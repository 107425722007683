<template>
  <div class="report rectBox boxb">
    <div class="labelHdader disfr jsb">
      <span>数据范围</span>
    </div>
    <el-form class="searchForm" size="small">
      <el-row>
        <el-col :span="24" class="disfr ac">
          <el-form-item>
            <el-select
              v-model="searchForm.dataTime"
              class="dataTime"
              @change="changeDataTime"
            >
              <el-option
                v-for="item in dataTimes"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              v-model="searchForm.date"
              @change="changeDate"
              value-format="yyyy-MM-dd"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              disabled
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" class="disfr ac flexWrap">
          <el-form-item>
            <el-input
              placeholder="请输入搜索内容"
              v-model="searchKeyword"
              class="input-with-select"
              style="width: 300px"
            >
              <el-select
                style="width: 100px"
                slot="prepend"
                v-model="searchKeywordCode"
                placeholder="请选择类型"
              >
                <el-option label="应用名称" value="title"></el-option>
                <el-option label="包名" value="packageName"></el-option>
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searchForm.businessUserId"
              @change="changeMultiple($event, 'businessUserId')"
              multiple
              clearable
              collapse-tags
              class="task"
              filterable
              placeholder="商务"
            >
              <el-option
                v-for="(item, index) in businessNameList"
                :key="index"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searchForm.userIdList"
              @change="changeMultiple($event, 'userIdList')"
              multiple
              clearable
              collapse-tags
              class="app"
              filterable
              placeholder="开发者"
            >
              <el-option
                v-for="item in devloperList"
                :key="item.userId"
                :label="item.companyName"
                :value="item.userId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="searchForm.countryCode"
              @change="changeMultiple($event, 'countryCode')"
              class="task"
              placeholder="国家定向"
              multiple
              clearable
              filterable
              collapse-tags
            >
              <el-option
                v-for="item in languageList"
                :key="item.countryCode"
                :label="item.countryName"
                :value="item.countryCode"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="padding: 10px 0">
            <el-button type="primary" class="formItem" @click="searchFn"
              >查询</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <PageTable
      id="statisticsTable"
      style="width: 100%"
      class="pageTable"
      :columns="columns"
      :tableData="tableData"
      @page-change="handleChangePage"
    >
      <template #downloadPvHb="{ row }">
        <span
          :style="{
            color: row.downloadPvHb.indexOf('-') !== -1 ? 'red' : 'green',
          }"
        >
          {{ row.downloadPvHb }}
        </span>
      </template>
      <template #installPvHb="{ row }">
        <span
          :style="{
            color: row.installPvHb.indexOf('-') !== -1 ? 'red' : 'green',
          }"
        >
          {{ row.installPvHb }}
        </span>
      </template>
      <template #conversionRateHb="{ row }">
        <span
          :style="{
            color: row.conversionRateHb.indexOf('-') !== -1 ? 'red' : 'green',
          }"
        >
          {{ row.conversionRateHb }}
        </span>
      </template>
    </PageTable>
  </div>
</template>

<script>
import { costTypeList } from "@/common/constant/constant.js";
import PageTable from "@/components/PageTable.vue";
import { getCpsReportList } from "./indexServe.js";
import {
  getAllAppStatementList,
  getBusinessList,
  cmsCountryAll,
} from "@/common/js/api";
import { getDeveloperUserList } from "@/views/business/developer/putinDeveloperList/indexServe.js";
import moment from "moment";

export default {
  name: "cpsHbReport",
  components: { PageTable },
  data() {
    return {
      costTypeList,
      searchKeyword: "",
      searchKeywordCode: "title",
      searchForm: {
        source: [""],
        dataTime: 7,
        date: [],
        envType: 0,
        costType: 1,
        countryCode: [""],
        taskIdList: [""],
        appIdList: [""],
        userIdList: [""],
        ployIdList: [""],
        ideaIdList: [""],
        businessUserId: [""],
      },
      value: [],
      dataTimes: [
        { label: "过去7天", value: 7, fn: "nowWeek" },
        { label: "过去14天", value: 14, fn: "nowMonth" },
        { label: "过去30天", value: 30, fn: "lastMonth" },
      ],
      appList: [{ appName: "全部应用", appName1: "" }],
      taskList: [{ taskName: "全部任务", id: "" }],
      devloperList: [{ companyName: "全部开发者", userId: "" }],
      strategyList: [{ ployName: "全部策略", id: "" }],
      originalityList: [{ ideaName: "全部创意", id: "" }],
      businessNameList: [{ name: "全部商务", id: "" }],
      initColumns: [
        { key: "pt", label: "日期" },
        { key: "businessName", label: "商务" },
        { key: "companyName", label: "开发者" },
        { key: "title", label: "应用名称" },
        { key: "packageName", label: "应用包名" },
        { slot: "downloadPvHb", label: "下载次数" },
        { slot: "installPvHb", label: "安装次数" },
        { slot: "conversionRateHb", label: "转化率" },
      ],
      columns: [],
      tableData: { list: [], size: 10, page: 1, total: 0 },
      languageList: [{ countryName: "全部地区", countryCode: "" }],
      formKey: [
        "userIdList",
        "appIdList",
        "taskIdList",
        "ployIdList",
        "ideaIdList",
        "businessUserId",
        "countryCode",
      ],
      listKey: ["getDevList", "getAppList"],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setDate(start.getDate() - 1);
              end.setDate(end.getDate() - 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "前三天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setDate(start.getDate() - 3);
              end.setDate(end.getDate() - 1);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  async mounted() {
    getBusinessList({}).then((res) => {
      if (res && res.code === 0) {
        this.businessNameList = [
          {
            name: "全部商务",
            id: "",
          },
        ].concat(res.data);
      }
    });
    this.getLanguageList();
    await this.init();
  },
  methods: {
    async init() {
      this.searchForm.date = this.getPastDateRange(7);
      await Promise.all([this.getAppList(), this.getDevList()]);
      await this.getReportList();
    },
    filterParamsList(key, listKey, listChildKey) {
      let list = this.searchForm[key].filter((a) => a !== "");
      if (list.length) return list;
      list = this[listKey]
        .filter((a) => a[listChildKey] !== "")
        .map((a) => a[listChildKey]);
      if (key == "userIdList") return [];
      return list;
    },
    async getAppList() {
      let res = await getAllAppStatementList({
        userId: this.filterParamsList(
          "userIdList",
          "devloperList",
          "userId"
        ).join(),
      });
      if (res.code !== 0) return;
      let curAppNameList = res.data.list.map((item) => ({
        ...item,
        appName: item.title,
        appName1: item.appId,
      }));
      this.appList = [{ appName: "全部应用", appName1: "" }].concat(
        curAppNameList
      );
    },
    async getDevList() {
      let res = await getDeveloperUserList({
        backendType: 1, //平台分类 1:开发者 ，2:代理商，3:推广后台
        identityStatus: 3,
        size: 30000,
      });
      if (res.code !== 0) return;
      let arr = res.data.list || [];
      const newArray = arr
        .filter((item) => item.companyName)
        .map((item) => ({ userId: item.id, companyName: item.companyName }));
      this.devloperList = [{ companyName: "全部开发者", userId: "" }].concat(
        newArray
      );
    },
    async getReportList(args) {
      let params = this.filterParams();
      params = { ...params, ...args };
      this.columns = this.initColumns;
      const res = await getCpsReportList(params);
      if (res.code !== 0) return;
      const data = res.data;
      this.tableData.list = data.list || [];
      this.tableData.total = data.total;
      this.tableData.page = params.page;
      this.tableData.size = params.size;
    },
    getPastDateRange(days) {
      const end = moment().format("YYYY-MM-DD"); // 当前日期
      const start = moment().subtract(days, "days").format("YYYY-MM-DD"); // 减去指定天数
      return [start, end];
    },
    changeDataTime(val) {
      // 获取过去7 14 30天的日期
      this.searchForm.date = this.getPastDateRange(val);
    },
    filterParams() {
      let params = { ...this.searchForm };
      params.page = this.tableData.page;
      params.size = this.tableData.size;
      if (this.searchKeyword.trim() !== "") {
        params[this.searchKeywordCode] = this.searchKeyword;
      }
      //环比类型 1、过去7天 2、过去14天 3、过去30天
      if (params.dataTime == 7) {
        params.hbType = 1;
      } else if (params.dataTime == 14) {
        params.hbType = 2;
      } else if (params.dataTime == 30) {
        params.hbType = 3;
      }
      params.startTime = params.date && params.date[0];
      params.endTime = params.date && params.date[1];
      params.userIdList = params.userIdList.filter((a) => a !== "");
      params.countryList = params.countryCode.filter((a) => a !== "");
      params.appIdList = params.appIdList.filter((a) => a !== "");
      params.taskIdList = params.taskIdList.filter((a) => a !== "");
      params.ployIdList = params.ployIdList.filter((a) => a !== "");
      params.ideaIdList = params.ideaIdList.filter((a) => a !== "");
      params.source = params.source.filter((a) => a !== "");
      params.manageIdList = params.businessUserId.filter((a) => a !== "");
      delete params.date;
      delete params.dataTime;
      delete params.countryCode;
      delete params.businessUserId;
      return params;
    },
    handleChangePage({ page, size }) {
      this.tableData.page = page;
      this.tableData.size = size;
      this.getReportList();
    },
    searchFn() {
      this.getReportList();
    },
    async changeDate(val) {
      this.searchForm.dataTime = [];
      this.dataTimes.forEach((a) => {
        let fnDate = this[a.fn]();
        if (val && fnDate[0] === val[0] && fnDate[1] === val[1]) {
          this.searchForm.dataTime = a.value;
        }
      });
    },
    async getLanguageList() {
      const res = await cmsCountryAll();
      this.languageList = [{ countryName: "全部地区", countryCode: "" }].concat(
        res.data
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./../../../../common/css/common.scss";
.report {
  height: auto;
  padding: 0 16px;
  .labelHdader {
    @extend .fz16;
    padding-right: 64px;
    line-height: 72px;
    margin-bottom: 6px;
    .headerOperation {
      .Oper {
        @extend .pointer;
        > img {
          @extend .inlinebox;
          @include rect(18px);
          margin-right: 6px;
        }
        + .Oper {
          margin-left: 42px;
        }
      }
    }
  }
  .searchForm {
    line-height: 32px;
    margin-bottom: 40px;
    .el-form-item {
      margin-bottom: 0;
      height: 32px;
    }
    /deep/.dataTime,
    /deep/.dataType {
      width: 108px;
    }
    /deep/.date {
      width: 296px;
    }
    /deep/ .app,
    .task,
    .formItem {
      width: 170px;
    }
    /deep/.el-form-item + .el-form-item {
      margin-left: 16px;
    }
    /deep/.el-select__tags {
      .el-tag {
        max-width: 70%;
      }
    }
  }
  .seriesBox {
    height: 40px;
    margin-bottom: 20px;
  }
  .pageTable {
    margin-bottom: 100px;
  }
}
</style>
